import store from '@/store/index'

const setRouteNameForModule = async (moduleName, suffix = null) => {
  try {
    const callback = store.getters['modules/appModuleName'];
    const displayName = callback(moduleName);
    
    store.dispatch(
      'appHeader/setRouteDisplayName',
      `${displayName}${suffix ? ` - ${suffix}`: ''}`
    );
  }
  catch {
    return;
  }
}

export const setCustomRouteName = (text, suffix = null) => store.dispatch(
  'appHeader/setRouteDisplayName',
  `${text}${suffix ? ` - ${suffix}`: ''}`
);

export default setRouteNameForModule;