const dateParseOptions = [
    'en-CA', // Converts the date to the required yyyy-MM-dd format.
    { timezone: 'cest', dateStyle: 'short' }
]

const timeParseOptions = [
    'nl-NL',
    { timeStyle: 'short' }
];

const getDateString = (date) =>
    (date instanceof Date && !isNaN(date))
        ? date.toLocaleString(...dateParseOptions)
        : '';

const getTimeString = (date) =>
    (date instanceof Date && !isNaN(date))
        ? date.toLocaleString(...timeParseOptions)
        : '';

export { getDateString, getTimeString }