<template>
  <div class="row">
    <router-link
      :to="{
        name: 'edit-project',
        params: { projectId: this.$route.params?.projectId }
      }"
    >
      <span
        class="btn btn-sm btn-primary float-end d-flex"
      >
        Aanpassen
        <span class="material-icons-round ms-2">
          edit_note
        </span>
      </span>
    </router-link>
  </div>

  <div class="row">
    <ProjectItemCard
      title="Item toevoegen"
      featured
      :is-new-item="false"
      :image="require('@/assets/img/events/meeting.webp')"
    />
    
    <ProjectItemCard
      v-for="projectItem in projectItems"
      :key="projectItem.id"
      :project-item-id="projectItem.id"
      :title="projectItem.title"
      :description="projectItem.description"
      :pinned-date="projectItem.pinnedDate"
      :create-date="projectItem.createDate"
      :read-count="projectItem.readCount"
      :image="projectItem.visualImage"
    />
  </div>
</template>

<script>
import { get as getProject } from '@/api/providers/projects';
import { getAll } from '@/api/providers/projectItems';
import ProjectItemCard from '@/components/ProjectOverview/Card.vue';

import { setCustomRouteName } from '@/helpers/route-name';

export default {
  name: 'ProjectOverview',

  components: {
    ProjectItemCard,
  },

  data: () => ({
    projectItems: [],
  }),

  beforeMount() {
    this.setRouteNameForProject();
  },

  mounted() {
    this.loadProjectItems();
  },

  methods: {
    async setRouteNameForProject() {
      let project = await getProject(this.$route.params.projectId);
      setCustomRouteName(project.title, "Berichten");
    },

    loadProjectItems() {
      getAll(this.$route.params.projectId)
        .then((items) => { 
          this.projectItems = items
        })
        .catch((error) => error.default());
    },
  },
};
</script>
