import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/project/item', data);
}

export function edit(data) {
  return putRequest(`/project/item/`, data)
}

export function get(id) {
  return getRequest(`/project/item/${id}/`);
}

export function getAll(projectId) {
  return postRequest(
    `/project/item/${projectId}/1`,
    {
      "currentPage": 1,
      "pageSize": 8008135,
    },
  )
    .then(({ projectItems }) => projectItems);
}

export function remove(id) {
  return deleteRequest(`/project/item/${id}`);
}
